import React from "react";
import {Secondary} from "../../components/layouts/Secondary/Secondary";
import styled from "styled-components";
import { Link } from "gatsby";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  @media (max-width: 599px) {
    padding: 50px 8.32%;
  }
  main {
    li {
      list-style: auto;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 27px;
      }
    }
    ul.list-style-none li {
      list-style: none;
    }
    ol.list-style-none li {
      list-style: none;
    }
    ol.list-style-none {
      padding: 0;
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    h2 {
      margin-top: 30px;
      font-size: 1.6rem;
      @media (max-width: 599px) {
        font-size: 1.3rem;
        line-height: 1.6;
      }
    }
    p {
      margin-top: 10px;
      text-align: left;
    }
    .ol-left-padding {
      padding-left: 90px;
    }
  }
`;

const TermsAndConditions = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: July 15, 2022</h2>
        <p>
          These Flahmingo Investments Inc. (&ldquo;Flahmingo&rdquo;,
          &ldquo;Us&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;,
          &ldquo;Our&rdquo;, &ldquo;We&rdquo; and &ldquo;we&rdquo;) Terms and
          Conditions (the &ldquo;Terms and Conditions&rdquo;) contain the terms
          and conditions governing access to and use of the Flahmingo mobile
          application (the &ldquo;Application&rdquo;), the&nbsp;
          <a href="http://www.flahmingo.com">www.flahmingo.com</a> website or
          affiliated sites providing Flahmingo services (the
          &ldquo;Site&rdquo;), and our consumer investment / financial services
          and any and all other services made available through the Application
          and Site (the &ldquo;Services&rdquo;). The Terms and Conditions are
          set by Flahmingo and are accepted by you (&ldquo;You&rdquo;,
          &ldquo;you&rdquo;, &ldquo;Your&rdquo; or &ldquo;your&rdquo;) upon your
          use of the Application, the Site, and/or the Application and the Site,
          as applicable.&nbsp;
        </p>
        <p>
          READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE DOWNLOADING,
          INSTALLING OR MAKING ANY USE OF THE APPLICATION OR MAKING ANY USE OF
          THE SITE. &nbsp;BY USING THE SITE, THE APPLICATION, OR THE SERVICES
          (INCLUDING REGISTERING FOR A USER ACCOUNT OR A TRADING ACCOUNT, AS
          APPLCABLE), YOU ARE SUBMITTING YOUR ACCEPTANCE TO THESE TERMS AND
          CONDITIONS AND ARE AGREEING TO BE BOUND BY AND BE A PARTY TO THESE
          TERMS AND CONDITIONS.&nbsp;
        </p>

        <p>
          IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU ARE
          PROHIBITED FROM USING OR ACCESSING THE APPLICATION AND THE SITE, YOU
          MUST NOT REGISTER FOR A USER ACCOUNT OR A TRADING ACCOUNT WITH US, AND
          YOU MUST NOT USE THE SERVICES.&nbsp;
        </p>

        <ol className="list-style-none">
          <li>
            <h2>I. LEGAL CAPACITY, AUTHORITY TO USE, AND NO USE BY MINORS</h2>
          </li>
        </ol>

        <ol>
          <li>
            <p>
              The Services, the Site, and the Application are not available to
              minors (e.g., persons under the age of majority). Unless otherwise
              disclosed, you hereby represent that if you are an individual, you
              reside in Canada, you have reached the legal age in your province
              of residence, you are under no legal incapacity and you have
              sufficient knowledge and experience to understand the nature and
              risks of the products to be traded.
            </p>
          </li>
        </ol>

        <ol start={2}>
          <li>
            <p>
              The Services, the Site, and the Application are intended for
              personal use. &nbsp;If your use of the Services, the Site, and/or
              the Application is on behalf of a corporation or other entity, you
              represent and warrant that such party has full knowledge of your
              actions and has consented thereto and that you have the authority
              to bind such entity to these Terms and Conditions.
            </p>
          </li>
        </ol>

        <ol start={3}>
          <li>
            <p>
              By using the Site, the Application, and/or the Services, you
              represent that are not a partner, director or employee of a
              member, member firm or member corporation of the Investment
              Industry Regulatory Organization of Canada (IIROC) or of any stock
              exchange, or of a non-member investment dealer, futures commission
              merchant, FINRA licensed broker-dealer or derivatives dealer
              (each, a Securities Industry Firm).
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>
              II. GENERAL ACKNOWLEDGEMENT AND CHANGES TO THE SERVICES AND THESE
              TERMS AND CONDITIONS
            </h2>
          </li>
        </ol>

        <ol start={4}>
          <li>
            <p>
              Flahmingo may, at any time and from time to time at its sole
              discretion, update, revise, supplement, replace, and/or otherwise
              modify these Terms and Conditions and/or impose new or additional
              rules, policies, terms, or conditions on You. &nbsp;Such updates,
              revisions, supplements, replacements, modifications, and
              additional rules, policies, terms, and conditions (collectively
              referred to as &ldquo;Revised Terms&rdquo;) will be effective
              immediately upon the Revised Terms being posted to the Site or the
              Application or by Flahmingo otherwise making them available to You
              (as the case may be). &nbsp;
            </p>
          </li>
        </ol>

        <ol start={5}>
          <li>
            <p>
              Any continued use of the Site or the Application following such
              posting of the Revised Terms to the Site or the Application, or
              otherwise making them available to you, will be deemed to
              constitute acceptance of the Revised Terms. &nbsp;If we do update
              the Terms and Conditions and You do not agree to the Revised
              Terms, You must stop using the Site and the Application.
            </p>
          </li>
        </ol>

        <ol start={6}>
          <li>
            <p>
              Flahmingo reserves the right, for any reason, in its sole
              discretion, to terminate, change, amend, or suspend the Site, the
              Application, or certain Services or functionalities of the Site
              and/or the Application. Flahmingo may impose limits on all or
              certain features of the Site and/or the Application or restrict
              your access to part or all of the Site and/or the Application
              without notice or liability to you.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>III. ACCOUNT SIGNUP AND ADMINISTRATION</h2>
          </li>
        </ol>

        <ol start={7}>
          <li>
            <p>
              You may register an account with Flahmingo to enable you to use
              features of the Site, the Application, and the Services.
              &nbsp;There are two kinds of accounts that may be registered with
              Flahmingo: a &ldquo;User Account&rdquo;, which allows you to use
              basic functionalities of the Site, the Application, and the
              Services including obtaining information on our Services and
              building your first test &ldquo;pie&rdquo;; and a &ldquo;Trading
              Account&rdquo;, which allows you to fund an account, purchase
              securities to fund you first active &ldquo;pie&rdquo; and execute
              trades through the Site, the Application, and the Services. &nbsp;
            </p>
          </li>
        </ol>

        <ol start={8}>
          <li>
            <p>
              The use and administration of a Trading Account is subject to a
              separate account agreement between You and Us. You will comply
              with all obligations set out in the account agreement between You
              and Us.
            </p>
          </li>
        </ol>

        <ol start={9}>
          <li>
            <p>
              You agree to provide accurate, current, and complete information
              required to register or create a User Account or Trading Account,
              and whatever information relating to You is required in connection
              with the Site, the Application, and/or the Services (the
              &ldquo;Registration Data&rdquo;). You further agree to maintain
              and update your Registration Data as required to keep it accurate,
              current, and complete. You are solely responsible for controlling
              access to your User Account or Trading Account, as applicable, and
              maintaining the confidentiality and security of your account and
              related password information. You must not use any other
              person&apos;s account, and You must not allow any login password
              or username for your User Account or Trading Account to be used by
              multiple users or individuals.&nbsp;
            </p>
          </li>
        </ol>

        <ol start={10}>
          <li>
            <p>
              Flahmingo reserves the right, for any reason, in its sole
              discretion, to terminate, change, amend, or suspend a User Account
              or Trading Account. Flahmingo may impose limits on all or certain
              features of or services made available through the Site, or
              restrict your access to part or all of your User Account or
              Trading Account, without notice or liability.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>IV. STATEMENTS, INFORMATION, AND INVESTMENT RISKS</h2>
          </li>
        </ol>

        <ol start={11}>
          <li>
            <p>
              Flahmingo&rsquo;s statements regarding its plans, directions and
              intent are subject to local laws, regulations and obtaining the
              necessary regulatory approvals and authorizations. Information and
              representations on the Site and Application are subject to
              changes, as set out in these Terms and Conditions, or withdrawal
              without notice at Flahmingo&rsquo;s sole discretion. Information
              on the Site and/or the Application should not be relied on in
              making a purchasing decision. Information on the Site and/or the
              Application is not an offer, commitment, promise or legal
              obligation to deliver any material, code or functionality.
            </p>
          </li>
        </ol>

        <ol start={12}>
          <li>
            <p>
              Should you choose to place such investment through the Services
              made available through the Site or the Application, you will have
              done this of your own volition and without relying on any
              information on the Site or the Application. If you are unsure
              about any aspect of the information provided or whether a
              particular investment is right for you, you should seek advice
              from an independent financial advisor, legal counsel or similar
              professional before using the Site or the Application and
              investing.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>V. REFERRAL PROGRAM</h2>
          </li>
        </ol>

        <ol start={13}>
          <li>
            <p>
              If you are the holder of a Flahmingo Trading Account, you may be
              entitled to voluntarily participate in the Flahmingo refer a
              friend program (the &ldquo;Referral Program&rdquo;). There may be
              a unique website link assigned to your account, referred to as
              your personalized referral link (a &ldquo;Referral Link&rdquo;).
              To participate in the Referral Program, you will need to refer
              friends who have not already signed up for an account
              (&ldquo;Friends&rdquo;) and invite your Friends to open an account
              using your Referral Link.
            </p>
          </li>
        </ol>

        <ol start={14}>
          <li>
            <p>
              You will earn referral perks (&ldquo;Referral Perks&rdquo;) in the
              amount/form we determine from time to time, once your Friend
              (&ldquo;Referred User&rdquo;) has spent or traded a certain amount
              or dollar value (&ldquo;Eligible Amount&rdquo;) in their Flahmingo
              account. The criteria for what constitutes an Eligible Amount is
              determined in our sole discretion, and may change over the course
              of the Referral Program. Referral Perks will be applied
              automatically to your Trading Account. The amount of Referral
              Perks earned and redeemed by you, and the Eligible Amount
              associated with Referral Perks, are subject to change at any time
              at Flahmingo&rsquo;s sole discretion.
            </p>
          </li>
        </ol>

        <ol start={15}>
          <li>
            <p>
              Participating in the Referral Program in no way guarantees that
              you will be approved as a Flahmingo Trading Account holder.
              Flahmingo reserves the right, at its sole discretion, to deny you
              or a Referred User access to a Flahmingo Trading Account in
              Flahmingo&rsquo;s sole discretion. &nbsp;In that case, you would
              not receive Referral Perks.
            </p>
          </li>
        </ol>

        <ol start={16}>
          <li>
            <p>
              The Referral Link may only be used for personal and non-commercial
              purposes. You may share your link with your personal connections
              via social media, email, or word of mouth, where you are the
              primary content owner. In cases where you are not the primary
              owner of the content or website (e.g., Wikipedia, coupon websites,
              affiliate websites, discussion forums, etc.), public distribution
              of your Referral Link is not allowed, however you are permitted to
              post your Referral Link on social media groups or chats for use by
              your friends, contacts, or followers. Promoting or advertising
              your Referral Link via search engine marketing or any other paid
              advertising media (e.g., Bing/Yahoo/Adwords) is not allowed. You
              are not allowed to misrepresent your relationship with Flahmingo,
              and you will not make any warranty or representation on behalf of
              Flahmingo. You are not allowed to refer yourself for the purposes
              of earning Referral Perks.
            </p>
          </li>
        </ol>

        <ol start={17}>
          <li>
            <p>
              Flahmingo reserves the right, at its sole discretion, to
              deactivate your Trading Account(s) and revoke the Referral Perks
              of any Trading Account holder who it finds or believes to be
              earning or using Referral Perks against these Terms and
              Conditions, or who is manipulating or abusing Flahmingo&rsquo;s
              Site, Application, and/or Services.
            </p>
          </li>
        </ol>

        <ol start={18}>
          <li>
            <p>
              We reserve the right, at any time and in our sole discretion, to
              add to or amend these Terms and Conditions in relation to the use
              of the Referral Program, or to vary or terminate the operation of
              Referral Perks issued pursuant to the Referral Program at any time
              without notice.
            </p>
          </li>
        </ol>

        <ol start={19}>
          <li>
            <p>
              We may from time to time offer rewards or other incentive programs
              (the &ldquo;Program&rdquo;) in connection with the Flahmingo Site,
              Services, and/or Application. You understand that eligibility for
              rewards under any such Program will be subject to the requirements
              of the Program and you must agree to be bound by the terms of the
              Program or any terms and conditions that apply to any such Program
              (to be posted to the Site, the Application, or otherwise made
              available to you by Flahmingo, and as may be amended or replaced
              from time to time). We reserve the right to cancel or amend any
              such Program without notice.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>VI. LICENSE AND RIGHT TO USE - SITE</h2>
          </li>
        </ol>

        <ol start={20}>
          <li>
            <p>
              The Site contains proprietary information and material that is
              owned by Flahmingo, its affiliates and/or licensors, and is
              protected by applicable intellectual property and other laws,
              including but not limited to copyright. Without limiting the
              foregoing, the content, organization, graphics, design,
              compilation, and other matters related to the Site, all
              information stored on and made available through the Site, and all
              documents, reports, forms, graphics, graphs, presentations, and
              other materials created or made available through the Site and/or
              the Services made available through the Site (collectively, the
              &ldquo;Website Content&rdquo;), are protected under applicable
              copyrights, trademarks and other proprietary (including but not
              limited to intellectual property) rights. &nbsp;You acknowledge
              and agree that you will not use such proprietary information,
              materials or Website Content in any way whatsoever except for use
              of the Services in compliance with these Terms and Conditions.
            </p>
          </li>
        </ol>

        <ol start={21}>
          <li>
            <p>
              You may use the Site and Website Content for the sole purpose of
              accessing and using the Services through the Site, including using
              your User Account or Trading Account established with Us. The
              viewing, printing or downloading of any Website Content from the
              Site grants you only a limited, nonexclusive license for use
              solely by you for your own personal use (or use by and on behalf
              of your organization) and not for republication, distribution,
              assignment, sublicense, sale, preparation of derivative works or
              other use. No part of any Website Content may be reproduced in any
              form except as expressly provided in these Terms and Conditions.
              No other download, retention, use, publication, or distribution of
              any portion of the Website Content is authorized or permitted, and
              you do not acquire ownership rights to any Website Content or
              other materials viewed or accessed through the Site.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>VII. LICENSE AND RIGHT TO USE &ndash; APPLICATION&nbsp;</h2>
          </li>
        </ol>

        <ol start={22}>
          <li>
            <p>
              For users of the Application, the Application contains proprietary
              information and material that is owned by Flahmingo, its
              affiliates and/or licensors, and is protected by applicable
              intellectual property and other laws, including but not limited to
              copyright. Without limiting the foregoing, the content,
              organization, graphics, design, compilation, and other matters
              related to the Application, all information stored on and made
              available through the Application, and all documents, reports,
              forms, graphics, graphs, presentations, and other materials
              created or made available through the Application and/or the
              Services made available through the Application (collectively, the
              &ldquo;Application Content&rdquo;), are protected under applicable
              copyrights, trademarks and other proprietary (including but not
              limited to intellectual property) rights. &nbsp;
            </p>
          </li>
        </ol>

        <ol start={23}>
          <li>
            <p>
              The Application is licensed to you, and not sold. &nbsp;Subject to
              these Terms and Conditions and any other agreement between You and
              Us, we grant to you (whether a single individual or entity) a
              limited, personal, revocable, non-exclusive, non-transferable
              license (the &ldquo;Application License&rdquo;) to download and
              install one (1) copy of the Application and use the Application
              Content on a single mobile smart phone, tablet device, or other
              computer (the &ldquo;Device&rdquo;) running a version of the
              operating system identified by us on an online store or app store
              run by an authorized third party and from which the Application is
              available for download as the minimum version required for the
              Application. The Application License is limited for use only by an
              individual user for a period commencing on the date of download or
              installation (whichever is earlier) and expiring on the date
              specified by us, in these Terms and Conditions, or in a separate
              written agreement between You and Us, or in the app store from
              which the Application is downloaded. &nbsp;
            </p>
          </li>
        </ol>

        <ol start={24}>
          <li>
            <p>
              The Application shall only be used on a Device running at all
              times the version of the operating system identified by us as the
              minimum version required for the Application to run unless and
              except where we may, in our sole and absolute discretion, provide
              any future update to the Application to adapt the Application to a
              newer version of the applicable operating system.
            </p>
          </li>
        </ol>

        <ol start={25}>
          <li>
            <p>
              Except for the limited Application License, and except as
              otherwise expressly set out in these Terms and Conditions, nothing
              in these Terms and Conditions shall be construed as conferring any
              licenses or other rights under the intellectual property or other
              proprietary rights of us and our licensors, affiliates or any
              third party. &nbsp;In addition, we and our licensors reserve the
              right to disable the Application once the Application License
              expires or is otherwise terminated under these Terms and
              Conditions.
            </p>
          </li>
        </ol>

        <ol start={26}>
          <li>
            <p>
              You shall not remove, modify or alter any copyright, trademark or
              other proprietary rights notices of us or any of our licensors and
              affiliates from any part of the Application, including, without
              limitation, any such notices contained in or on the electronic
              and/or physical (if any) media or documentation or contained in or
              dynamically or otherwise created by any of the Application.
              &nbsp;Any copies which you are permitted to make pursuant to these
              Terms and Conditions must contain the same copyright and other
              proprietary notices that appear on the original Application.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>VIII. USER CONDUCT</h2>
          </li>
        </ol>

        <ol start={27}>
          <li>
            <p>
              You agree to use the Site and/or the Application, as applicable,
              in a responsible manner that is in full compliance with these
              Terms and Conditions, and with your local laws and regulations.
              You shall not, and you agree not to use the Site, the Application,
              or any Service or functionality of the Site or the Application to:
            </p>
          </li>
        </ol>
        <ol className="list-style-lower-roman">
          <li>
            <p>post or transmit any material which,</p>
          </li>
        </ol>
        <ol className="ol-left-padding">
          <li>
            <p>violates or infringes in any way upon the rights of others,</p>
          </li>
          <li>
            <p>
              is unlawful, threatening, abusive, defamatory, hurtful, invasive
              of privacy or publicity rights, vulgar, obscene, indecent,
              hateful, profane or otherwise objectionable, or
            </p>
          </li>
          <li>
            <p>
              contains or links to viruses, worms, Trojan horses or other code
              with malicious, disruptive and/or destructive features, or any
              computer code, files or programs designed to (or that operates to)
              interrupt, destroy or limit the functionality of any computer
              software or hardware or telecommunications equipment or otherwise
              interfere with or disrupt the Site and/or the Application;
            </p>
          </li>
        </ol>
        <ol start={2} className="list-style-lower-roman">
          <li>
            <p>
              download, retain, modify, translate, use, publish, distribute,
              reproduce, copy, or transfer the right to use the Site, the
              Application, or any portion of the Site, except as expressly
              permitted by these Terms and Conditions;&nbsp;
            </p>
          </li>
          <li>
            <p>
              use or attempt to use another person&rsquo;s Account, Service, or
              personal information, or impersonate another person or entity;
            </p>
          </li>
          <li>
            <p>
              restrict or inhibit any other person from using or enjoying the
              Site, the Application, or the Services offered through the Site or
              the Application;
            </p>
          </li>
          <li>
            <p>
              advertise or perform any commercial solicitation, including, but
              not limited to, the solicitation of other users of the Site or the
              Application;
            </p>
          </li>
          <li>
            <p>
              gain unauthorized access to any hardware or software systems or
              networks associated with the Services, or obtain any services or
              information not intentionally made available to you by Flahmingo
              on or through the Site and/or the Application;
            </p>
          </li>
          <li>
            <p>
              remove, circumvent, disable, damage or otherwise interfere with
              any access control or security-related features of the Site and/or
              the Application;&nbsp;
            </p>
          </li>
          <li>
            <p>
              engage in practices such as &ldquo;screen scraping&rdquo;,
              &ldquo;database scraping&rdquo; or &ldquo;automated form
              filling&rdquo; to retrieve, test or obtain lists of users,
              content, or other information generated or processed by or stored
              via the Site and/or the Application;&nbsp;
            </p>
          </li>
          <li>
            <p>
              other than use of the Site, the Application, and the Services by
              you in accordance with these Terms and Conditions, offer, for a
              fee or free of charge, services consisting of processing data
              through the use of the Site, the Application, any User Account or
              Trading Account, or the Services; or
            </p>
          </li>
          <li>
            <p>
              upload, post or otherwise make available on or through the Site or
              the Application any material protected by copyright, trademark or
              other proprietary right without the express permission of the
              owner of the copyright, trademark or other proprietary right.
            </p>
          </li>
        </ol>

        <ol start={28}>
          <li>
            <p>
              Without limiting the remedies available to Flahmingo at law or in
              equity, Flahmingo may terminate your rights to use or access the
              Site and/or the Application if you are found to be using the Site
              and/or the Application to engage in any of the foregoing
              activities.
            </p>
          </li>
        </ol>

        <ol start={29}>
          <li>
            <p>
              By submitting material to any public area of Site or the
              Application, you automatically grant, or warrant that the owner of
              such material has expressly granted Flahmingo the royalty-free,
              perpetual, irrevocable, non-exclusive right and license to use,
              reproduce, modify, adapt, publish, translate and distribute such
              material (in whole or in part) worldwide, and/or to incorporate it
              in other works in any form, media or technology now known or
              hereafter developed for the full term of any copyright that may
              exist in such material.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>IX. FLAHMINGO TRADEMARKS</h2>
          </li>
        </ol>

        <ol start={30}>
          <li>
            <p>
              The Site and the Application may contain trademarks, service
              marks, logos, icons and trade names that are the property of
              Flahmingo (collectively, the &ldquo;Flahmingo Trademarks&rdquo;)
              or such other party as is indicated with respect to that
              trademark, service mark, logo, icon, or trade name. &nbsp;Products
              and names mentioned on the Site and/or the Application may be
              trademarks of their respective owners. All trademarks reproduced
              in the Site and/or the Application which are not the property of,
              or licensed to, Flahmingo, are acknowledged on the Site and/or the
              Application, as applicable. You are not permitted to use the
              Flahmingo Trademarks for any purposes other than those specified
              in these Terms and Conditions.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>X. PERSONAL INFORMATION COLLECTION, USE AND DISCLOSURE</h2>
          </li>
        </ol>

        <ol start={31}>
          <li>
            <p>
              Flahmingo will collect, use and disclose your personal information
              in accordance with Flahmingo&rsquo;s Privacy Policy. As defined
              more particularly in our Privacy Policy, &ldquo;Personal
              Information&rdquo; is defined as personally identifiable
              information, or any information that identifies you as an
              individual. It includes information that you provide to Flahmingo
              or that Flahmingo collects from any third party. Our Privacy
              Policy is a part of and is incorporated into these Terms and
              Conditions. &nbsp;
            </p>
          </li>
        </ol>

        <ol start={32}>
          <li>
            <p>
              In the event of any inconsistency between these Terms and
              Conditions and the Privacy Policy with respect to the collection,
              use and disclosure of your personal information, the Privacy
              Policy shall govern. &nbsp;If you have any questions about the
              collection, use or disclosure of your Personal Information, please
              contact Flahmingo at:&nbsp;
              <a href="mailto:compliance@flahmingo.com">
                compliance@flahmingo.com
              </a>
              .&nbsp;
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XI. DISCLAIMERS AND LIMITATIONS OF LIABILITY</h2>
          </li>
        </ol>

        <ol start={33}>
          <li>
            <p>
              USE OF THE SITE, THE APPLICATION, AND THE SERVICES IS AT YOUR OWN
              RISK. &nbsp;TO THE EXTENT PERMITTED BY THE APPLICABLE LAW, THE
              SITE, THE APPLICATION, AND ALL SERVICES ACCESSED OR USED FROM THE
              SITE OR THE APPLICATION IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND
              &ldquo;AS AVAILABLE&rdquo; BASIS, AND WITHOUT ANY REPRESENTATIONS,
              WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED.
              ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS,
              IMPLIED, STATUTORY OR OTHERWISE ARE HEREBY DISCLAIMED TO THE
              FULLEST EXTENT PERMITTED BY LAW. FLAHMINGO DOES NOT WARRANT THE
              QUALITY, ACCURACY OR COMPLETENESS OF ANY CLAIMS, STATEMENTS OR
              INFORMATION ON THE SITE OR THE APPLICATION, AND WILL NOT UNDER ANY
              CIRCUMSTANCES BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY LOSS OR
              DAMAGES (DIRECT, INDIRECT, SPECIAL, ECONOMIC, INCIDENTAL,
              CONSEQUENTIAL, PUNITIVE OR EXEMPLARY) IN RESPECT OF YOUR USE OF,
              OR INABILITY TO USE, OR ANY RELIANCE ON, THE SITE, THE
              APPLICATION, THE WEBSITE CONTENT, THE APPLICATION CONTENT, AND/OR
              THE SERVICES. &nbsp;
            </p>
          </li>
        </ol>

        <ol start={34}>
          <li>
            <p>
              FLAHMINGO, ITS PARENTS, SUBSIDIARIES, AFFILIATES AND PARTNERS AND
              EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND
              AGENTS, AND ANY OTHER PARTIES INVOLVED IN CREATING, PRODUCING,
              DELIVERING, OR MONITORING THE SITE AND THE APPLICATION AND EACH OF
              THEIR SUCCESSORS OR ASSIGNS (COLLECTIVELY, THE
              &ldquo;RELEASEES&rdquo;) WILL NOT UNDER ANY CIRCUMSTANCES BE
              LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY DAMAGES
              WHATSOEVER ARISING FROM, CONNECTED WITH, OR RELATING TO THE SITE
              OR THE APPLICATION. YOU EXPRESSLY ACKNOWLEDGE THAT FLAHMINGO HAS
              ENTERED INTO THESE TERMS AND CONDITIONS WITH YOU AND MAKES THE
              SITE AND APPLICATION AVAILABLE TO YOU, IN RELIANCE UPON THE
              LIMITATIONS AND EXCLUSIONS OF LIABILITY AND THE DISCLAIMERS SET
              FORTH HEREIN, AND THAT THE SAME FORM AN ESSENTIAL BASIS OF THE
              BARGAIN BETWEEN YOU AND FLAHMINGO.&nbsp;
            </p>
          </li>
        </ol>

        <ol start={35}>
          <li>
            <p>
              The Site and Application are only intended to provide you with
              general information and are neither an offer to sell nor a
              solicitation of an offer to purchase any security and may not be
              relied upon for investment purposes. &nbsp;Information on the Site
              and the Application is not intended to provide legal, accounting,
              financial or tax advice, and should not be relied upon in that
              regard. &nbsp;Any commentaries and information contained in the
              Site and/or the Application should not be considered personal
              investment advice.
            </p>
          </li>
        </ol>

        <ol start={36}>
          <li>
            <p>
              Flahmingo will not provide any recommendations to you and will not
              be responsible for making a suitability determination of trades
              when accepting orders from you. You alone will be responsible for
              your own investment decisions and Flahmingo will not consider your
              financial situation, investment knowledge, investment objectives,
              savings objectives and risk tolerance when accepting orders from
              you. &nbsp;Please consult with your professional advisor with
              respect to your particular circumstances.
            </p>
          </li>
        </ol>

        <ol start={37}>
          <li>
            <p>
              Electronic transmission of information has inherent insecurities.
              Flahmingo cannot guarantee the complete confidentiality or
              security for information that is transmitted electronically. By
              accessing the Site and/or the Application, you acknowledge that
              Flahmingo is not responsible for any damages or losses you may
              suffer as a result of your electronic transmission of confidential
              or sensitive information to us.
            </p>
          </li>
        </ol>

        <ol start={38}>
          <li>
            <p>
              Past performance is not a reliable indicator of future
              performance. You should not rely on any past performance as a
              guarantee of future investment performance. Forecasts may also not
              be a reliable indicator of future performance. &nbsp;Testimonials
              may not be representative of the experience of other customers and
              are not guarantees of future performance or success. When you
              invest, it is possible that the performance of your investments
              will not be consistent with other customers who give testimonials.
              &nbsp;You may lose some or all of your investment.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XII. INDEMNITY</h2>
          </li>
        </ol>

        <ol start={39}>
          <li>
            <p>
              You agree to defend, indemnify and hold harmless the Releasees,
              from and against all claims and expenses and from any liabilities
              or damages whatsoever, including any direct, incidental,
              consequential, indirect or special damages, or any other losses,
              costs or expenses of any kind (including legal fees, expert fees,
              or other disbursements) which may arise, directly or indirectly,
              in connection with a breach of these Terms and Conditions or in
              connection with access to or use of the Site, the Application, or
              the Services, or in the delay or inability to use the Site, the
              Application, or the Services, even if there is a fundamental
              breach or if Flahmingo has been advised of the possibility of
              liability or damages.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XIII. COOKIES</h2>
          </li>
        </ol>

        <ol start={40}>
          <li>
            <p>
              You acknowledge and agree that Flahmingo may store personal and
              certain other information about you on your computer in the form
              of &ldquo;cookies&rdquo;. Such use of cookies is solely in
              connection with the operation of the Site. You acknowledge and
              agree that if you prohibit or restrict the placement of cookies on
              your computer, such prohibition or restriction may have an adverse
              effect on your use of the Site.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XIV. LINKS TO THIRD PARTY SITES</h2>
          </li>
        </ol>

        <ol start={41}>
          <li>
            <p>
              This Site or the Application may contain links to websites or
              applications operated by parties other than Flahmingo. Such links
              are provided for your reference only. Flahmingo does not control
              such websites or applications and is not responsible for their
              content or practices. The websites and applications of third
              parties are not monitored or checked for accuracy,
              appropriateness, or completeness by us. No endorsement of any
              third party products or services is expressed or implied by any
              information, material or content referred to or included on, or
              linked from or to the Site or the Application. Your access and use
              of such third party websites or applications, including
              information, material, products and services therein, are solely
              at your own risk.&nbsp;
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XV. TERMINATION</h2>
          </li>
        </ol>

        <ol start={42}>
          <li>
            <p>
              If you breach any provision of these Terms and Conditions, you may
              no longer use the Site or the Application. &nbsp;Flahmingo will
              determine your compliance with Terms and Conditions in our sole
              discretion and our decision shall be final and binding and not
              subject to challenge or appeal. We may, in our sole discretion,
              change, suspend or terminate, temporarily or permanently, the
              Site, the Application, or any part thereof or any of its features
              at any time, for any reason, without any notice or liability to
              you or any other entity. &nbsp;
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XVI. GOVERNING LAW</h2>
          </li>
        </ol>

        <ol start={43}>
          <li>
            <p>
              By accessing or using the Site, the Application, or the Site and
              the Application, including signing up for a User Account or a
              Trading Account, you acknowledge and agree that these Terms and
              Conditions and all matters relating to your use of the Site and
              the Application shall be governed by and construed in accordance
              with the laws of the Province of Alberta (without regard to
              conflict of law principles) and the Federal laws of Canada
              applicable therein. All actions and disputes arising from or
              relating to these Terms and Conditions shall be heard and decided
              exclusively before the courts located within the Province of
              Alberta, Canada and you irrevocably attorn and submit to the
              exclusive jurisdiction of the courts of the Province of Alberta
              and waive any right you might otherwise have to claim lack of
              personal jurisdiction or inconvenience of forum.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XVII. INTERPRETATION AND ENTIRE AGREEMENT</h2>
          </li>
        </ol>

        <ol start={44}>
          <li>
            <p>
              Any reference to &ldquo;agree&rdquo;, &ldquo;agrees&rdquo;,
              &ldquo;represent&rdquo;, &ldquo;represents&rdquo;,
              &ldquo;acknowledge&rdquo;, &ldquo;acknowledges&rdquo;, or any
              &ldquo;acknowledgement&rdquo; by you in these Terms and
              Conditions, shall refer in all cases to your acceptance of these
              Terms and Conditions through making use of the Site, the
              Application, or the Site and the Application, including signing up
              for a User Account or a Trading Account. &nbsp;These Terms and
              Conditions constitute the entire and only agreement between us and
              you with respect to the subject matter hereof.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XVIII. SEVERABILITY AND NO WAIVER</h2>
          </li>
        </ol>

        <ol start={45}>
          <li>
            <p>
              No consent or waiver by either party to, or of any breach or
              default by, the other party in its performance of its obligations
              under this agreement will be deemed or construed to be a consent
              to, or a waiver of, a continuing breach or default or any other
              breach or default of those or any other obligations of that party.
              No consent or waiver will be effective unless in writing.
            </p>
          </li>
        </ol>

        <ol start={46}>
          <li>
            <p>
              If any provision of these Terms and Conditions is found to be
              unlawful, void, or for any reason unenforceable, then that
              provision shall be deemed to be severed from the rest of these
              Terms and Conditions and shall not affect the validity and
              enforceability of any remaining provisions.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>XIX. CHOICE OF LANGUAGE</h2>
          </li>
        </ol>

        <ol start={47}>
          <li>
            <p>
              The parties acknowledge that they require that these Terms and
              Conditions be drawn up in the English language only. &nbsp;Les
              parties reconnaissent qu&rsquo;elles ont exig&eacute; que la
              pr&eacute;sente convention soit r&eacute;dig&eacute;e en langue
              anglaise seulement.
            </p>
          </li>
        </ol>

        <ol className="list-style-none">
          <li>
            <h2>Other Important Links</h2>
          </li>
        </ol>

        <ul className="list-style-none">
          <li>
            <Link className="link" to="/promo/sign-up-promo/">
              $10 Promotion Terms & Conditions
            </Link>
          </li>
        </ul>
      </main>
    </Wrapper>
  </Secondary>
);
export default TermsAndConditions;
